export { matchers } from "/.svelte-kit/generated/client/matchers.js?t=1743834726736";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js?t=1743835612162"),
	() => import("/.svelte-kit/generated/client/nodes/1.js?t=1743834726739"),
	() => import("/.svelte-kit/generated/client/nodes/2.js?t=1743835064123")
];

export const server_loads = [];

export const dictionary = {
		"/": [2]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from "/.svelte-kit/generated/root.js?t=1743834726739";